import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
// import Instagram from '../../components/instagram';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import Style from '../../styles/recruit/recruit.module.scss';
import ImgMitsukawa from '../../images/recruit/mitsukawa.jpg';

class Recruit extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
  }

  render() {
    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    return (
      <Layout>
        <Transition ref={this.child}>
          <Header transition={transition} />
          <main className={Style.recruit}>
            <section className={`top ${Style.top}`}>
              <h1 className="heading">Join our <br className="spDisBlock" />team</h1>
              <p className="intro">
                碁盤の目の街を縫うように、路地がひしめく京都。
                <br className="pcDisBlock" />
                その数、約13,000本とも。
                <br className="pcDisBlock" />
                そこには観光とは無縁の、京都の素顔が広がっています。
                <br className="pcDisBlock" />
                Googleでさえ、知らない世界へ。
              </p>
            </section>
            <section className={`sectionContent ${Style.positions}`}>
              <figure>
                <img src={ImgMitsukawa} alt="光川貴浩" />
              </figure>
              <div className={`${Style.inner}`}>
                <div className={Style.left}>
                  <p className={Style.profession}>Webエンジニア</p>
                  <h2 className={`heading ${Style.heading}`}>
                    「編集」という力で、
                    <br className="pcDisBlock" />
                    想像もできない価値を
                    <br className="pcDisBlock" />
                    生み出していく。
                  </h2>
                  <p className="intro">コンクリートの土手だって、見方を変えればジャンプ台になる。編集の力で、世の中の「当たり前」を変え、まだ想像もできない価値を生み出していく。</p>
                  <p className="btn">
                    <TransitionLink to="/recruit/interview-01" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                      <span>Read message</span>
                    </TransitionLink>
                  </p>
                </div>
              </div>
            </section>
            <section className={`sectionContent ${Style.positions}`}>
              <figure>
                <img src={ImgMitsukawa} alt="光川貴浩" />
              </figure>
              <div className={`${Style.inner}`}>
                <div className={Style.left}>
                  <p className={Style.profession}>Webデザイナー</p>
                  <h2 className={`heading ${Style.heading}`}>
                    「編集」という力で、
                    <br className="pcDisBlock" />
                    想像もできない価値を
                    <br className="pcDisBlock" />
                    生み出していく。
                  </h2>
                  <p className="intro">コンクリートの土手だって、見方を変えればジャンプ台になる。編集の力で、世の中の「当たり前」を変え、まだ想像もできない価値を生み出していく。</p>
                  <p className="btn">
                    <TransitionLink to="/recruit/interview-01" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                      <span>Read message</span>
                    </TransitionLink>
                  </p>
                </div>
              </div>
            </section>
            {/*<Instagram isHeading />*/}
          </main>
          <Footer transition={transition} />
        </Transition>
      </Layout>
    );
  }
}

export default Recruit;
